<div class="container-fluid">

   <!-- Float Buttons -->

   <div class="floatBtn d-flex flex-column" *ngIf="!hideFloatBtns">
      <button id="sendEmail" type="button" class="btn btn-circle btn-circle-md  mb-2 bg-navy-blue" (click)="sendProductEmail()"
         *ngIf="selectedProductForCatalogCount>0 && selectedProductForCatalogCount&lt;11 && userAccess?.canMaintainBlanksProduct" matTooltip="Send Blanks Product Email" matTooltipPosition="left"
         matTooltipClass="bg-dark"><i class="fa fa-envelope text-light fa-lg"></i></button>

      <button type="button" (click)="downloadSelectedImages()" class=" btn btn-circle btn-circle-md bg-info mb-2" *ngIf="selectedProductForCatalogCount>0"
         matTooltip="Download selected blanks product images as zip" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-file-archive fa-lg" style="color: white;"></i></button>

      <button type="button" (click)="resetCatalogSelection()" class=" btn btn-circle btn-circle-md bg-dark mb-2" *ngIf="selectedProductForCatalogCount>0"
         matTooltip="Reset blanks product selection for catalog" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-undo-alt fa-lg" style="color: white;"></i></button>

      <button type="button" (click)="selectAll()" class=" btn btn-circle btn-circle-md bg-warning mb-2" *ngIf="userAccess?.canCreateCatalog && productList?.length>0"
         matTooltip="Select all in current list" matTooltipPosition="left" matTooltipClass="bg-dark"><span><img style=" max-width:50%;" src="/assets/select-all.png"></span></button>

      <!--
      <a [routerLink]="['/product-catalog']" class="btn btn-circle btn-circle-md mb-2" style="background-color: tomato;" *ngIf="selectedProductForCatalogCount>0" matTooltip="Preview Catalog"
         matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-eye  fa-lg"></i></a>
      -->

      <button type="button" class="btn btn-circle btn-circle-md bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});"
         matTooltipPosition="left" matTooltipClass="bg-dark">
         <i class="fas fa-arrow-up fa-lg" style="color:white"></i>
      </button>
   </div>


   <div class="row">

      <!-- External Filter Component -->
      <div class="col-sm-3 col-lg-2 col-md-3 border" *ngIf="showSideBar">
         <ng-container *ngIf="searchConfig &&  searchConfig.filterType === 'EXTERNAL' && searchConfig.filterConfigs && searchConfig.filterConfigs.length>0">
            <app-external-filter [searchConfig]="searchConfig" (event)="handleFilterEvent($event)"></app-external-filter>
         </ng-container>
      </div>


      <!-- Centre Portion with Search Box and Results -->

      <div class="col-sm col-lg col-md mt-1">
         <section class="gallery-block cards-gallery">

            <div class="container-fluid">

               <div class="row d-flex justify-content-center">
                  <h2 class="d-inline">Blanks Products</h2>
                  <button type="button" [routerLink]="['/blanks-product/create']" class="btn btn-circle btn-circle-md btn-circle btn-circle-md-md d-inline ml-4"
                     *ngIf="userAccess?.canMaintainBlanksProduct && !hideAddProduct" matTooltip="Create New Blanks Product" matTooltipPosition="right" matTooltipClass="bg-dark">
                     <i class="fas fa-plus fa-lg text-light"></i>
                  </button>
               </div>
               <hr>

               <app-search #searchComponent [placeholder]="'SKU, Manufacturer, Composition, Blanks Quality, GSM etc. (space delimited)'" [searchConfig]="searchConfig" [existingList]="productList"
                  [sortColumns]="sortColumns"></app-search>

               <div class="row mt-2">
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-2 px-2" *ngFor="let product of productList; index as index" [@fadeInOut]>
                     <app-product-image-card id="imageCard_{{index}}" [index]="index" [product]="product" [userAccess]="userAccess" (onSelect)="childSelectionChanged($event)" (onDelete)="search()"
                        [stopProductNavigation]="stopProductNavigation" [stopProductDeletion]="stopProductDeletion" [enableExclusiveSelection]="enableExclusiveSelection"></app-product-image-card>
                  </div>
               </div>

               <hr>

            </div>
         </section>
      </div>

   </div>

</div>


<!-- ************************************************* START - EMAIL ****************************************************************************** -->

<button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>
<div *ngIf="showEmailEditor">
   <app-compose-email [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail"></app-compose-email>
</div>

<!-- ************************************************* END - EMAIL ****************************************************************************** -->