import { Component, OnInit, HostListener, Input, ViewChild } from '@angular/core';
import { Product } from 'src/app/shared/model/product.model';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { BlanksManufacturerMetadataService } from 'src/app/shared/service/blanks-manufacturer-metadata.service';
import { BlanksManufacturerMetadata } from 'src/app/shared/model/blanks-manufacturer-metadata.model';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { SearchService } from 'src/app/shared/service/search.service';
import { FilterConfig } from 'src/app/shared/model/common/search-filter.model';
import { SearchComponent } from 'src/app/common/search/search.component';

@Component({
   selector: 'app-blanks-product-list',
   templateUrl: './blanks-product-list.component.html',
   styleUrls: ['./blanks-product-list.component.css'],
   animations: [
      trigger('fadeInOut', [
         state('void', style({
            opacity: 0
         })),
         transition('void => *', animate(500)),
      ]),
      trigger('EnterLeave', [
         state('flyIn', style({ transform: 'translateX(0)' })),
         transition(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('0.5s 300ms ease-in')
         ]),
         transition(':leave', [
            animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
         ])
      ]),
      trigger('balloonEffect', [
         state('void', style({
            backgroundColor: 'green',
            transform: 'scale(0.2)'
         })),
         state('*', style({
            backgroundColor: 'red',
            transform: 'scale(1)'
         })),
         transition('void=>*', animate('1000ms')),
         transition('*=>void', animate('1500ms'))
      ]),
   ]
})

export class BlanksProductListComponent implements OnInit {

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   productList: Product[] = [];
   colorList = [];
   garmentList = [];
   treatmentList = [];
   categoryList = [];
   manufacturerList = [];
   manufacturerSeriesList = [];
   compositionList: string[] = [];
   qualityList = [];
   gsmList: string[] = [];
   selectedProductForCatalogCount: number = 0;
   showEmailEditor: boolean = false;
   composeEmail: ComposeEmail = new ComposeEmail;
   searchConfig: SearchDetails;

   sortColumns = [
      { columnName: 'SKU', sortByName: 'SKU' },
      { columnName: 'UPDATED_ON', sortByName: 'Last Updated' }
   ];

   @Input() hideFloatBtns: boolean = false;
   @Input() hideAddProduct: boolean = false;
   @Input() stopProductNavigation: boolean = false;
   @Input() stopProductDeletion: boolean = false;
   @Input() showSideBar: boolean = true;
   @Input() brandSku: string;
   @Input() enableExclusiveSelection: boolean = false;
   @Input() showAdhocProduct: boolean = false;

   @ViewChild('searchComponent') searchComponent: SearchComponent<Product>;

   constructor(private authService: AuthService, private sharedService: SharedService,
      private productCatalogService: ProductCatalogService, private composeEmailService: ComposeEmailService,
      private productMetadataService: ProductMetadataService, private blanksManufacturerMetadataService: BlanksManufacturerMetadataService,
      private searchService: SearchService) { }

   ngOnInit() {
      console.log('showAdhocProduct', this.showAdhocProduct);
      this.handleSubscriptions();
      if (!this.searchConfig) {
         this.searchConfig = this.searchService.blanksProductListSearchDetails;
         this.setFilterConfigs();
      }
      this.loadBlanksManufacturerMetadataDetails();
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
      window.scrollTo(0, 0);
   }

   setFilterConfigs() {
      this.searchConfig.filterType = 'EXTERNAL';
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         this.addChecListFilerConfig('Manufacturer', this.manufacturerList, 'MANUFACTURER');
         this.addChecListFilerConfig('Manufacturer Sku', this.manufacturerSeriesList, 'MANUFACTURER_SERIES');
         this.addChecListFilerConfig('Garment', this.garmentList, 'GARMENT');
         this.addChecListFilerConfig('Colors', this.colorList, 'COLOR');
         this.addChecListFilerConfig('Treatment', this.treatmentList, 'TREATMENT');
         this.addChecListFilerConfig('GSM', this.gsmList, 'GSM');
         this.addChecListFilerConfig('Quality', this.qualityList, 'QUALITY');
         this.addChecListFilerConfig('Composition', this.compositionList, 'COMPOSITION');
      }
   }

   private addChecListFilerConfig(label: string, optionList: any[], fieldName: string) {
      let manufacturerFilterConfig: FilterConfig = {
         filterType: 'IN_CHECKLIST',
         label: label,
         currentSelection: 'ALL',
         options: optionList,
         optionDislpayVar: 'display',
         optionValueVar: 'value',
         fieldName: fieldName
      };
      this.searchConfig.filterConfigs.push(manufacturerFilterConfig);
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.productMetadataService.colorListObservable.subscribe(res => {
         this.setFilterConfigMasterList(res, this.colorList);
      });

      this.productMetadataService.categoryListObservable.subscribe(res => {
         this.setFilterConfigMasterList(res, this.categoryList);
      });

      this.productMetadataService.treatmentListObservable.subscribe(res => {
         this.setFilterConfigMasterList(res, this.treatmentList);
      });

      this.productMetadataService.garmentListObservable.subscribe(res => {
         this.setFilterConfigMasterList(res, this.garmentList);
      });
   }


   private setFilterConfigMasterList(res: string[], masterList: any[]) {
      if (!masterList) {
         masterList = [];
      }
      let objectList: any[] = this.convertToFilterObject(res);
      masterList.length = 0;
      masterList.push(...objectList);
   }


   loadBlanksManufacturerMetadataDetails() {
      this.blanksManufacturerMetadataService.getBlanksManufacturerMetadataList().toPromise().then(res => {
         if (res && res.responseStatus.status === 'SUCCESS' && res.responsePayload != null) {
            let response: BlanksManufacturerMetadata[] = res.responsePayload;

            let manufacturerListTemp: string[] = response.map(x => x.manufacturer);
            this.setFilterConfigMasterList(manufacturerListTemp, this.manufacturerList);

            let manufacturerSeriesListTemp: string[] = response.map(x => x.manufacturerSeries);
            this.setFilterConfigMasterList(manufacturerSeriesListTemp, this.manufacturerSeriesList);

            let compositionListTemp: string[] = response.map(x => x.composition);
            this.setFilterConfigMasterList(compositionListTemp, this.compositionList);


            let qualityListTemp: string[] = response.map(x => x.quality);
            this.setFilterConfigMasterList(qualityListTemp, this.qualityList);

            let gsmListTemp: string[] = response.map(x => x.gsm);
            this.setFilterConfigMasterList(gsmListTemp, this.gsmList);
         }
      });
   }

   private convertToFilterObject(stringArray: string[]) {
      let distinctSourceStringList: string[] = [...new Set(stringArray)];
      let sortedStringList = this.sharedService.sort(distinctSourceStringList);
      let objectList: any[] = [];
      sortedStringList.forEach(item => {
         let itemObj = { value: item, display: item };
         objectList.push(itemObj);
      });
      return objectList;
   }

   handleFilterEvent(event:any){
      if(event==='APPLY_FILTER'){
         this.search();
      }
   }

   search() {
      if (this.searchComponent) {
         this.searchComponent.search();
      }
   }

   async selectAll() {
      this.searchConfig.lastSelection = 400;
      this.selectCurrentFilteredListForCatalog();
   }

   downloadSelectedImages() {
      this.productCatalogService.download();
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   /******************************* START - CATALOG RELATED ************************** */

   childSelectionChanged(selection: boolean) {
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   selectCurrentFilteredListForCatalog() {
      this.productList.forEach(element => {
         if (element.productImages.length > 0) {
            this.productCatalogService.addProduct(element);
         }
      });
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   resetCatalogSelection() {
      this.productCatalogService.selectedProductsForCatalog = [];
      if (this.productCatalogService.catalogSettings) {
         this.productCatalogService.catalogSettings.catalogName = 'Catalog';
      }
      this.selectedProductForCatalogCount = this.productCatalogService.selectedProductsForCatalog.length;
   }

   /******************************* EBD - CATALOG RELATED ************************** */

   /******************************* START - EMAIL ********************************** */

   sendProductEmail() {
      this.showEmailEditor = true;
      let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
      emailConfig.productIdList = this.productCatalogService.getSelecedProductIdList();
      emailConfig.templateName = 'productDetailsWithVisual';
      this.composeEmail = new ComposeEmail;
      this.getEmailTemplate(emailConfig);
   }

   getEmailTemplate(emailConfig: EmailTemplateConfig) {
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp: ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent = composeEmailTemp.emailContent;
         this.composeEmail.emailTo = composeEmailTemp.emailTo;
         this.composeEmail.emailCc = composeEmailTemp.emailCc;
         this.composeEmail.emailSubject = composeEmailTemp.emailSubject;
         document.getElementById('composeEmailToggler').click();
      });
   }

   /******************************* END - EMAIL ********************************** */

}
